<template>
  <div>
    <h1 :class="active == item.id ? 'title mt-3 active' : 'title mt-3'" data-toggle="collapse" href="#collapseid" @click="$emit('subFolder', item)"> 
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill rotate" viewBox="0 0 16 16">
      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </svg> 
      {{item.name}}
    </h1>
  </div>
</template>

<script>
export default {
  props: ['item', 'active']
}
</script>

<style scoped>
* {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2474B2;
  cursor: pointer;
}

.title:hover {
  color: #2699f1;
}

.rotate {
  transform: rotate(0deg);
  fill: #F38235;
}

.rotateActive {
  transform: rotate(90deg)
}

.active {
  color: #F38235;
}

</style>